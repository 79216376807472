<template>
  <section>
    <b-card title="Info">
      <validation-form
        ref="validationForm"
        :submit-buttons="{
          loading: form.isLoading,
          cancel: isNewEvent
        }"
        @validated="isNewEvent ? create() : update()"
        @cancel="$router.push({ name: 'home'})"
      >
        <!-- Logo -->
        <image-input
          :file.sync="form.logo"
          :preview-src.sync="form.logo_url"
          label="League Logo"
        />

        <b-row class="my-2">
          <!-- Name -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.name"
              name="event-name"
              vid="name"
              rules="required"
              label="League Name*"
              placeholder="League Name"
              aria-autocomplete="none"
            />
          </b-col>

          <!-- Abbreviation -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.abbreviation"
              rules="max:8"
              vid="abbreviation"
              name="event-abbreviation"
              label="League Abbreviation"
              placeholder="League Abbreviation"
              aria-autocomplete="none"
            />
          </b-col>

          <!-- Sport -->
          <b-col sm="6">
            <form-validation-field
              v-model="form.sport"
              vid="sport"
              name="sport"
              component="BFormSelect"
              rules="required"
              mode="lazy"
              label="Sport*"
              value-field="id"
              text-field="name"
              :options="sports"
            />
          </b-col>

          <!-- Gender -->
          <b-col sm="6">
            <select-gender
              v-model="form.gender"
              league
            />
          </b-col>
        </b-row>

        <!-- Social Media -->
        <form-social-media
          :social-media="form.social_media || undefined"
          @update:social-media="form.social_media = $event"
        />

        <!-- Address -->
        <form-address
          :address="form.address || undefined"
          @update:address="form.address = $event"
        />

        <!-- Contact -->
        <form-contact
          :contact="form.contact || undefined"
          @update:contact="form.contact = $event"
        />

      </validation-form>
    </b-card>

    <card-delete
      v-if="!isNewEvent"
      item-name="league"
      @delete="$store.commit('app/deleteModal/UPDATE_DELETE_MODAL',{
        title: 'Delete League',
        itemName: form.name,
        endpoint: `/events/${event.id}`,
        afterDelete() {
          $router.push({ name: 'home' })
        }
      })"
    />
  </section>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import ImageInput from '@/components/forms/images/ImageInput.vue'
import FormSocialMedia from '@/components/forms/FormSocialMedia.vue'
import FormAddress from '@/components/forms/FormAddress.vue'
import FormContact from '@/components/forms/FormContact.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import CardDelete from '@/components/cards/CardDelete.vue'
import SelectGender from '@/components/forms/selects/SelectGender.vue'

import Form from '@/forms/Form'
import { mapMutations, mapState } from 'vuex'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventInformation',
  components: {
    BCard,
    BRow,
    BCol,
    ValidationForm,
    FormValidationField,
    ImageInput,
    FormSocialMedia,
    FormAddress,
    FormContact,
    CardDelete,
    SelectGender,
  },
  data() {
    return {
      form: new Form({
        name: '',
        abbreviation: '',
        logo: undefined,
        logo_url: '',
        organization_id: null,
        sport: 'basketball',
        gender: null,
        type: null,
        contact: null,
        social_media: null,
        address: null,
      }, true),
      sports: [
        { id: null, name: 'Select a sport', disabled: true },
        { id: 'basketball', name: 'Basketball' },
        { id: 'volleyball', name: 'Volleyball' },
      ],
    }
  },
  computed: {
    ...mapState('event', ['event']),
    isNewEvent() {
      return !this.$route.params.event
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.params.event) {
      next()
      return
    }

    try {
      const { data } = await axios.get(`events/${to.params.event}`)

      next(vm => {
        Object.assign(vm.form, data.data)
      })
    } catch (error) {
      flashError(error)
      next()
    }
  },
  mounted() {
    if (!this.isNewEvent) {
      Object.assign(this.form, this.event)
    }
    this.form.organization_id = this.$store.state.account.activeOrganization
    this.form.type = 'league'
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    ...mapMutations('event', ['UPDATE_EVENT']),
    create() {
      this.form.post('/events')
        .then(response => {
          const { data } = response

          this.UPDATE_EVENT(data)
          this.$router.push({ name: 'event.information', params: { event: data.id } })
        })
    },
    update() {
      this.form.post(`/events/${this.$route.params.event}`, false, true)
        .then(response => {
          const { data } = response

          this.UPDATE_EVENT(data)
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
